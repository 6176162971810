
import i18n from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';
// import FsLightbox from "fslightbox-vue";
import medicalDiscountsViewCard from './medical-discounts-view-card.vue';
import RestaurantImageCaption from '@/modules/restaurant/components/restaurant-image-caption.vue';

export default {
  name: 'app-restaurant-view-page',
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      record: 'MedicalDiscounts/view/record',
      current: 'branch/view/branch',
      loading: 'MedicalDiscounts/view/loading',
    }),
    isRTL() {
      return i18n.locale == 'ar';
    },
    evenNumbers() {
      if (this.record) {
        return this.record.images
          .map((_, i) => i)
          .filter((i) => i % 3 === 0);
      }
      return [];
    },
    options() {
      return {
        rewind: true,
        gap: '1.25rem',
        perPage: 2,
        breakpoints: {
          640: {
            perPage: 1,
            gap: '1rem',
          },
          480: {
            perPage: 1,
            gap: '1rem',
          },
        },
      };
    },
  },
  data() {
    return {
      slide: 0,
      toggler: false,
      RestaurantImageCaption: RestaurantImageCaption,
      modalImages: [],
      captionArray: [],
      slideIndex: 0,
      starbucks: [
        {
          id: '0',
          name: { ar: '', en: '' },
          description: {
            ar: 'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير من الملاحظات او الانتقادات للتصميم الاساسيوخلافاَ للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من كتاب "حول أقاصي الخير والشر',
            en: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
          },
          backgroundImage: '/images/menus/healthy-care.jpg',
          thumbnail: '/images/pharma-banner.webp',
          location: 'Smouha Gate 2',
          phoneNumber: '+201111286077',
          openHours: 'Everyday 9 AM - 11 PM',
          discounts: [
            {
              discountName: {
                ar: ' اعضاء نادي سموحة',
                en: 'Members of Smouha Club',
              },
              discountPrice: '40%',
            },
            {
              discountName: {
                ar: 'خصم خاص للاعبين نادي سموحة',
                en: 'Special discount for Smouha club players',
              },
              discountPrice: '30%',
            },
            {
              discountName: {
                ar: 'خصم خاص للموظفين بنادي سموحة',
                en: 'Special discount for Smouha club employees',
              },
              discountPrice: '30%',
            },
          ],
        },
        {
          id: '1',
          name: { ar: '', en: '' },
          description: {
            ar: 'لوريم ايبسوم هو نموذج افتراضي يوضع في التصاميم لتعرض على العميل ليتصور طريقه وضع النصوص بالتصاميم سواء كانت تصاميم مطبوعه ... بروشور او فلاير على سبيل المثال ... او نماذج مواقع انترنت ...وعند موافقه العميل المبدئيه على التصميم يتم ازالة هذا النص من التصميم ويتم وضع النصوص النهائية المطلوبة للتصميم ويقول البعض ان وضع النصوص التجريبية بالتصميم قد تشغل المشاهد عن وضع الكثير من الملاحظات او الانتقادات للتصميم الاساسيوخلافاَ للاعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد. من كتاب "حول أقاصي الخير والشر',
            en: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.",
          },
          backgroundImage: '/images/menus/agial-banner.jpg',
          thumbnail: '/images/menus/agial-banner.jpg',
          location: 'Smouha Gate 2',
          phoneNumber: '+201111286077',
          openHours: 'Everyday 9 AM - 11 PM',
          discounts: [
            {
              discountName: {
                ar: 'العيادات الخارجية',
                en: 'Outpatient clinics',
              },
              discountPrice: '30%',
            },
            {
              discountName: {
                ar: 'القسم الداخلي',
                en: 'interior section',
              },
              discountPrice: '15%',
            },
            {
              discountName: {
                ar: 'معامل التحاليل',
                en: 'Analysis laboratories',
              },
              discountPrice: '15%',
            },
            {
              discountName: {
                ar: 'الحقن المجهري',
                en: 'ICSI',
              },
              discountPrice: '15%',
            },
          ],
        },
        {
          id: '2',
          name: { ar: '', en: '' },
          description: {
            ar: 'معامل راد لاب إحدى شركات مجموعة دار الاشعه تقدم خدمة جميع التحاليل الطبية بجودة عالمية تحت إشراف نخبة من أساتذة التحاليل الطبية باستخدام أحدث الأجهزة ، يمكنك الآن عمل الاشعه والتحاليل فى مكان واحد بأي فرع من فروع دار الأشعة ..',
            en: 'Rad Lab, one of the companies of the Radiology House Group, provides all medical analyzes with international quality under the supervision of a group of medical analysis professors using the latest equipment. You can now do x-rays and analyzes in one place in any branch of the Radiology House.',
          },
          backgroundImage:
            '/images/menus/red-lab-banner.jpg',
          thumbnail: '/images/menus/red-lab-banner.jpg',
          location: 'Smouha Gate 2',
          phoneNumber: '034811440',
          openHours: 'Everyday 9 AM - 11 PM',
          discounts: [
            {
              discountName: { ar: 'اكس راي', en: 'X-Ray' },
              discountPrice: '20%',
            },
            {
              discountName: {
                ar: 'فحوصات السونار',
                en: 'Ultrasound examinations',
              },
              discountPrice: '20%',
            },
            {
              discountName: {
                ar: 'الرنين المغناطيسي',
                en: 'Magnetic Resonance',
              },
              discountPrice: '10%',
            },
            {
              discountName: {
                ar: 'الاشعة المقطعية',
                en: 'CT scan',
              },
              discountPrice: '10%',
            },
          ],
        },
      ],
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
      },
    };
  },
  components: {
    // FsLightbox,
    medicalDiscountsViewCard,
  },
  methods: {
    openImageModal(index) {
      this.toggler = !this.toggler;
      this.slideIndex = index;
    },
    ...mapActions({
      doFind: 'MedicalDiscounts/view/doFind',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    getFirstWordPresenter(description) {
      if (this.isRTL) {
        return this.getFirstWord(description['ar']);
      } else {
        return this.getFirstWord(description['en']);
      }
    },
    getFirstWord(str) {
      let spaceIndex = str.indexOf(' ');
      return spaceIndex === -1
        ? str
        : str.substr(0, spaceIndex);
    },
    getSecondWordPresenter(description) {
      if (this.isRTL) {
        return this.getSecondWord(description['ar']);
      } else {
        return this.getSecondWord(description['en']);
      }
    },
    getSecondWord(str) {
      return str.split(/\s+/).slice(1, 2).toString();
    },
    removeFirstTwoWord(description) {
      let str = this.isRTL
        ? description['ar']
        : description['en'];
      str = str.split(/\s+/).slice(2, str.length);
      return str.join(' ');
    },
    namePresenter(value) {
      return this.isRTL ? value['ar'] : value['en'];
    },
  },
  async created() {
    await this.doFind(this.$route.params.id);
    console.log('record', this.record);
  },
};
